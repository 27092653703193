import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';

@Component({
    selector: 'ngx-echarts-pie-report-total-cost',
    template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsPieReportTotalCostComponent implements AfterViewInit, OnChanges {
    options: any = {};
    themeSubscription: any;

    @Input() dados: any[];

    constructor(private theme: NbThemeService) {
    }
    ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

            const colors = config.variables;
            const echarts: any = config.variables.echarts;

            const data1 = ['Misto', 'Administrativo', 'Técnico', 'Call Center', 'Data Center']
            var data2 = [
                { value: this.dados[1], name: data1[1] },
                { value: this.dados[0], name: data1[0] },
                { value: this.dados[2], name: data1[2] },
                { value: this.dados[3], name: data1[3] },
                { value: this.dados[4], name: data1[4] }
            ]

            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.warningLight, colors.infoLight, colors.dangerLight, colors.successLight, colors.primaryLight],
                responsive: true,
                tooltip: {
                    trigger: 'item',
                    formatter:
                        function (params) {

                            var value = params.data.value//.toFixed(2).toString();
                            //value = value.replace(/\./g, ",");
                            var msg = params.name + ': <br> R$ ' + (value).toLocaleString('pt-br') + ' ( ' + (params.percent).toLocaleString('pt-br') + ' %)'

                            return msg;
                        }

                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    type: 'scroll',
                    data: data1,
                    textStyle: {
                        color: echarts.textColor,
                    },
                },
                series: [
                    {
                        name: 'Valores faturados',
                        type: 'pie',
                        radius: '65%',
                        center: ['50%', '60%'],
                        data: data2,
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: echarts.itemHoverShadowColor,
                            },
                        },
                        label: {
                            normal: {
                                textStyle: {
                                    color: echarts.textColor,
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                lineStyle: {
                                    color: echarts.axisLineColor,
                                },
                            },
                        },
                    },
                ],
            };
        });
    }

    ngAfterViewInit() {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

            const colors = config.variables;
            const echarts: any = config.variables.echarts;

            const data1 = ['Misto', 'Administrativo', 'Técnico', 'Call Center', 'Data Center']
            var data2 = [
                { value: this.dados[0], name: data1[0] },
                { value: this.dados[1], name: data1[1] },
                { value: this.dados[2], name: data1[2] },
                { value: this.dados[3], name: data1[3] },
                { value: this.dados[4], name: data1[4] }
            ]

            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.warningLight, colors.infoLight, colors.dangerLight, colors.successLight, colors.primaryLight],
                responsive: true,
                tooltip: {
                    trigger: 'item',
                    formatter:
                        function (params) {

                            var value = params.data.value.toFixed(2).toString();
                            value = value.replace(/\./g, ",");
                            var msg = params.name + ': <br> R$ ' + value + ' ( ' + params.percent + ' %)'

                            return msg;
                        }

                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    data: data1,
                    textStyle: {
                        color: echarts.textColor,
                    },
                },
                series: [
                    {
                        name: 'Valores faturados',
                        type: 'pie',
                        radius: '65%',
                        center: ['50%', '60%'],
                        data: data2,
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: echarts.itemHoverShadowColor,
                            },
                        },
                        label: {
                            normal: {
                                textStyle: {
                                    color: echarts.textColor,
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                lineStyle: {
                                    color: echarts.axisLineColor,
                                },
                            },
                        },
                    },
                ],
            };
        });
    }


}
