import { AfterViewInit, Component, OnChanges, Input } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { HourlyDataService } from '../../../../service/hourly_data.service';
import { HourlyData } from '../../../../model/hourly_data.model';

@Component({
    selector: 'ngx-echarts-bar-energia',
    template: `
    <div echarts [options]="options" class="echart" style="margin-left:15px"></div>
  `,
})
export class EchartsBarEnergiaComponent implements OnChanges, AfterViewInit {
    @Input() dataHourlyData: HourlyData[];

    options: any = {};
    themeSubscription: any;

    arrayOfValues: HourlyData[];

    yDataAtivaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    yDataAtivaForaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    yDataUferPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    yDataUferForaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


    xAxisData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

    constructor(private theme: NbThemeService, datePipe: DatePipe) {
    }

    ngAfterViewInit() {

        if (this.dataHourlyData) {
            this.limpaDados();
            this.arrayOfValues = this.dataHourlyData;

            this.arrayOfValues.forEach(item => {
                let hora = parseInt(item.hora);

                if (item.postoTarifario == "PONTA") {
                    this.yDataAtivaPonta[hora] = item.energiaAtiva;
                    this.yDataAtivaForaPonta[hora] = 0;
                    this.yDataUferPonta[hora] = item.ufer;
                    this.yDataUferForaPonta[hora] = 0;
                } else if (item.postoTarifario == "FORA_PONTA") {
                    this.yDataAtivaPonta[hora] = 0;
                    this.yDataAtivaForaPonta[hora] = item.energiaAtiva;
                    this.yDataUferPonta[hora] = 0;
                    this.yDataUferForaPonta[hora] = item.ufer;
                }
            });

            this.carregaGrafico();
        }
    }

    ngOnChanges(changes: import("@angular/core").SimpleChanges): void {

        if (this.dataHourlyData) {
            this.limpaDados();
            this.arrayOfValues = this.dataHourlyData;

            this.arrayOfValues.forEach(item => {
                let hora = parseInt(item.hora);

                if (item.postoTarifario == "PONTA") {
                    this.yDataAtivaPonta[hora] = item.energiaAtiva;
                    this.yDataAtivaForaPonta[hora] = 0;
                    this.yDataUferPonta[hora] = item.ufer;
                    this.yDataUferForaPonta[hora] = 0;
                } else if (item.postoTarifario == "FORA_PONTA") {
                    this.yDataAtivaPonta[hora] = 0;
                    this.yDataAtivaForaPonta[hora] = item.energiaAtiva;
                    this.yDataUferPonta[hora] = 0;
                    this.yDataUferForaPonta[hora] = item.ufer;
                }
            });

            this.carregaGrafico();
        }
    }

    carregaGrafico() {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

            const colors: any = config.variables;
            const echarts: any = config.variables.echarts;

            var emphasisStyle = {
                itemStyle: {
                    barBorderWidth: 1,
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    shadowColor: 'rgba(0,0,0,0.5)'
                }
            };

            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.primaryLight, colors.infoLight],
                tooltip: {
                    trigger: 'axis',
                    formatter:
                        function (params) {
                            var texto = '';
                            params.forEach(dados => {
                                if (dados.seriesIndex in [0, 1])
                                    texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + ' kVAh ' + '<br>'
                                else {
                                    texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + ' kVArh ' + '<br>'
                                }
                            });

                            // adding X label
                            texto += 'Hora: ' + params[0].axisValueLabel + ':00'

                            return texto;
                        }
                    , position: function (pos, params, dom, rect, size) {

                        if (size.viewSize[0] < 500) {
                            if (+(pos[0] > size.viewSize[0] * 0.2)) {
                                return [size.viewSize[0] * 0.2, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        } else {
                            if (+(pos[0] > size.viewSize[0] * 0.7)) {
                                return [size.viewSize[0] * 0.7, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        }
                    }
                },
                legend: {
                    type: 'scroll',
                    data: ['Ativa Ponta', 'Ativa Fora Ponta', 'UFER Ponta', 'UFER Fora Ponta'],
                    align: 'left',
                    textStyle: {
                        color: echarts.textColor,
                    },

                },
                grid: {
                    left: '3%',
                    right: '8%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        splitLine: { show: false },
                        data: this.xAxisData,
                        silent: false,
                        //name: '[ Hora ]',
                        align: 'center',
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            }
                        },
                    },
                ],
                yAxis: [
                    {
                        name: '[ kVAh ]',
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: echarts.splitLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br')
                            }
                        },
                    },
                    {
                        name: '[ kVArh ]',
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: echarts.splitLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br')
                            }
                        },
                    }
                ],
                series: [
                    {
                        name: 'Ativa Ponta',
                        type: 'bar',
                        data: this.yDataAtivaPonta,
                        stack: 'one',
                        emphasis: emphasisStyle,
                        //itemStyle: { color: 'red' },
                        animationDelay: idx => idx * 10,
                    },
                    {
                        name: 'Ativa Fora Ponta',
                        type: 'bar',
                        data: this.yDataAtivaForaPonta,
                        stack: 'one',
                        emphasis: emphasisStyle,
                        itemStyle: { color: 'green' },
                        animationDelay: idx => idx * 10,
                    },
                    {
                        name: 'UFER Ponta',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: this.yDataUferPonta,
                        itemStyle: { color: 'red' },
                        animationDelay: idx => idx * 10 + 100,
                    },
                    {
                        name: 'UFER Fora Ponta',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: this.yDataUferForaPonta,
                        itemStyle: { color: 'orange' },
                        animationDelay: idx => idx * 10 + 100,
                    },

                ],
                animationEasing: 'elasticOut',
                animationDelayUpdate: idx => idx * 5,
            };

        });
    }

    limpaDados() {
        this.yDataAtivaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.yDataAtivaForaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.yDataUferPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.yDataUferForaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


        this.xAxisData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
    }
}
