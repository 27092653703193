import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceMaster } from './service.master';

@Injectable()
export class TemperaturaService {


    constructor(private http: HttpClient)
    {

    }

    getDadosByUnit(unidadeId,date)
    {
        // @date = dd-mm-yyyy format
        // @unitId = id from consumerUnit
        return this.http.get(ServiceMaster.baseURL + '/temperature/find/'+unidadeId+'/'+date, { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }

    getCameraFriaTemp(cameraId, periodo) {
        return this.http.get(ServiceMaster.baseURL + '/logIOsAtiva/find/' + cameraId + "/" + periodo, { headers: ServiceMaster.getHeaders() });
    }


}
