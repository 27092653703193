import { NbSidebarService } from '@nebular/theme';
import { Component } from '@angular/core';

@Component({
    selector: 'ngx-one-column-layout',
    styleUrls: ['./one-column.layout.scss'],
    template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive >
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column (click)="closeSidebar()">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {


    constructor(private sidebarService: NbSidebarService,) {
    }

    closeSidebar() {
        let largura = window.innerWidth;

        if(largura < 576)//resolucaoPequena
            this.sidebarService.collapse('menu-sidebar');
        else if(largura < 1200)
            this.sidebarService.compact('menu-sidebar');
    }
}
