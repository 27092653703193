import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceMaster } from './service.master';
import { HourlyData } from '../model/hourly_data.model';
import { EconomyPotential } from '../model/economy_potential.model';

//@ todo VERIFICAR A NECESSIDADE DESTE ARQUIVO, este conteudo poderia ser adiciondo a HourlyDataService 
@Injectable()
export class EconomyPotentialService {


    constructor(private http: HttpClient) {
    }

    getEconomyPotentialTodayByEquipamentId(id: string) {
        return this.http.get(ServiceMaster.baseURL + '/hourly_data/economy?equipamentId=' + id, { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }

}