import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxAuthRoutingModule } from './auth-routing.module';
import { NbAuthModule } from '@nebular/auth';
import {
    NbAlertModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbIconModule,
    NbCardModule,
    NbSpinnerComponent,
    NbSpinnerModule
} from '@nebular/theme';
import { LoginComponent } from './login/login.component';
import { AuthService } from '../service/auth/auth.service';
import { ComponentModule } from '../pages/component.module';
import { UserService } from '../service/user.service';


@NgModule({
    declarations: [LoginComponent],
    imports: [
        NbCardModule,
        NgxAuthRoutingModule,
        CommonModule,
        FormsModule,
        RouterModule,
        NbAlertModule,
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule,
        NgxAuthRoutingModule,
        NbAuthModule,
        NbIconModule,
        NbSpinnerModule,
        ComponentModule
    ],
    providers: [
        AuthService,
        FormBuilder,
        UserService
    ]
})
export class NgxAuthModule {
}
