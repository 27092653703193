import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceMaster } from '../service.master';

@Injectable()
export class AuthService {

    // store the URL so we can redirect after logging in
    redirectUrl: string;

    /*   about user authenticated   */
    isLoggedIn = false;
    userAuthenticated;
    userNameAuthenticated;
    role = 'operador';


    constructor(private http: HttpClient) { }

    setSecureToken(secure_token: string) {
        ServiceMaster.setNewTokenInHeaders(secure_token);
        sessionStorage.setItem("tokenTelefonica", secure_token);
    }

    getSecureToken() {
        return sessionStorage.getItem("tokenTelefonica");
    }

    setUsername(username: string) {
        this.userNameAuthenticated = username;
        sessionStorage.setItem("username", username);
    }

    getUserName() {
        if (sessionStorage.getItem("username") === null) return '';
        else return sessionStorage.getItem("username");
    }

    //Check User is LoggedIn or not!
    isLogged() {

        var token = this.getSecureToken();
        if (token !== null) {
            ServiceMaster.setNewTokenInHeaders(token);
            this.userNameAuthenticated = this.getUserName()
            return true;
        }
        return false;
    }

    userAuthenticatedIsAdmin() {
        return this.http.get(ServiceMaster.baseURL + '/auth/read/' + this.getUserName(),
            { headers: ServiceMaster.getHeaders(), observe: 'response' }
        );
    }

    login(usernameLogin, passwordLogin) {
        return this.http.post(ServiceMaster.baseURL + '/auth/signin',
            {
                username: usernameLogin,
                password: passwordLogin

            }, { headers: ServiceMaster.getHeaders(), observe: 'response' }
        );
    }

    getUserAuthenticated() {
        this.http.get(ServiceMaster.baseURL + '/auth/read/' + this.userNameAuthenticated,
            { headers: ServiceMaster.getHeaders(), observe: 'response' }
        ).subscribe(data => {
            this.userAuthenticated = data.body;
            this.role = this.userAuthenticated.role;
        }, error => {
            console.error('Error: ', error)
        });
    }

    getAllUsers() {
        return this.http.get(ServiceMaster.baseURL + '/auth/read/all',
            { headers: ServiceMaster.getHeaders(), observe: 'response' }
        );
    }

    public static verifyLogged() {
        var token = sessionStorage.getItem("tokenTelefonica");
        if (token == null || token == undefined) {
            localStorage.clear()
            sessionStorage.clear()
            window.location.replace("");
        }
    }

    logout(): void {
        this.isLoggedIn = false;
        ServiceMaster.setNewTokenInHeaders('')
    }
}
