import { Component } from '@angular/core';


/* <span class="created-by">Created with ♥ by <b><a href="https://akveo.com" target="_blank">Akveo</a></b> 2019</span>
<div class="socials">
  <a href="#" target="_blank" class="ion ion-social-github"></a>
  <a href="#" target="_blank" class="ion ion-social-facebook"></a>
  <a href="#" target="_blank" class="ion ion-social-twitter"></a>
  <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
</div> */
@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <!-- Footer -->
<footer class="page-footer font-small blue">

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2020 Copyright:
    <a href="https://www.ativasolucoes.com.br/">www.ativasolucoes.com.br</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
  `,
})
export class FooterComponent {
}
