import { AfterViewInit, Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

import { NbThemeService } from '@nebular/theme';
import { Temperatura } from '../../../../model/temperatura.model';
import { TemperaturaService } from '../../../../service/temperatura.service';
import { echart } from 'echarts';

@Component({
    selector: 'ngx-echarts-line-temperature',
    template: `
    <div echarts [options]="options" class="echart" style="height: 300px;"></div>
  `,
})
export class EchartsLineComponentTemperature implements AfterViewInit {
    idCameraFriaMarthe = '617bdada7422d507a4c68d33';
    options: any = {};
    themeSubscription: any;

    unitId: String = "";
    temperatures: Temperatura[] = [];

    @Input() inputTemperature: any[];

    constructor(private theme: NbThemeService, private route: ActivatedRoute, private temperaturaService: TemperaturaService, private datePipe: DatePipe, private router: Router) {

        this.route.queryParams.subscribe(dados => {
            // console.log('router id', dados);

            this.unitId = dados.unidadeId
        });
        if (this.unitId != this.idCameraFriaMarthe) {
            temperaturaService.getDadosByUnit(this.unitId, datePipe.transform(new Date(), 'dd-MM-yyyy')).subscribe((response) => {
                // console.log('temps', response);

                this.temperatures = <Temperatura[]>response.body[0].temperature;
                this.plotChart();
            }, (error) => {
                console.error('[Temperatura] Erro ao buscar temperaturas.')
            })
        }
    }

    ngOnInit() {
        //console.log(this.unitId);

        if (this.unitId == this.idCameraFriaMarthe) {
            // console.log('ssss',this.inputTemperature);
            this.inputTemperature.forEach(temp => {
                this.temperatures.push({
                    date: new Date(temp.dataHoraLog),
                    value: temp.valorPorta
                })
            });

            this.plotChart();
        }
    }

    ngAfterViewInit() {

    }

    plotChart() {
        // console.log('plt');

        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

            const colors: any = config.variables;
            const echarts: any = config.variables.echarts;

            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.danger],
                tooltip: {
                    trigger: 'item',
                    formatter:
                        function (params) {
                            return `Temperatura</br>${(new Date(params.data.date)).getHours()}:00 h: ${(params.data.value).toLocaleString('pt-br')} °C`;
                        },
                },
                legend: {
                    align: 'left',
                    type: 'scroll',
                    data: ['Temperatura',],
                    textStyle: {
                        color: echarts.textColor,
                    },
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                            formatter:
                                function (value, index) {
                                    return `${(new Date(value)).getHours()}h`;
                                },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: echarts.splitLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                            formatter: "{value} °C"
                        },
                    },
                ],
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                series: [
                    {
                        name: 'Temperatura',
                        type: 'line',
                    }
                ],
                dataset: {
                    dimensions: ['date', 'value'],
                    source: this.temperatures,
                },

            };
        });
    }





}
