import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceMaster } from './service.master';
import { User } from '../model/user/user.model';

@Injectable()
export class UserService {

    constructor(private http: HttpClient) { }

    getUserById(id: string) {
        return this.http.get(ServiceMaster.baseURL + `/user/find/id/${id}`, { headers: ServiceMaster.getHeadersUpload(), observe: 'response' });
    }

    getMe() {
        return this.http.get(ServiceMaster.baseURL + `/user/myself`, { headers: ServiceMaster.getHeadersUpload(), observe: 'response' });
    }

    updateMe(user: User) {
        return this.http.patch(ServiceMaster.baseURL + `/user/myupdate`, user, { headers: ServiceMaster.getHeadersUpload(), observe: 'response' });
    }

    getAllUsers(){
        return this.http.get(ServiceMaster.baseURL + `/user/find/all`, { headers: ServiceMaster.getHeadersUpload(), observe: 'response' });
    }

    createUser(user:User){
        return this.http.post(ServiceMaster.baseURL + `/user/save`, user, { headers: ServiceMaster.getHeadersUpload(), observe: 'response' });
    }

    updateUser(user: User) {
        return this.http.patch(ServiceMaster.baseURL + `/user/update`, user, { headers: ServiceMaster.getHeadersUpload(), observe: 'response' });
    }

    deleteUser(userId){
        return this.http.delete(ServiceMaster.baseURL + `/user/remove/${userId}`, { headers: ServiceMaster.getHeadersUpload(), observe: 'response' });
    }


}
