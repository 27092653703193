import { Component, Output, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Router } from '@angular/router';
import { MESES } from '../../../../util/legendas_grafico.const';

@Component({
  selector: 'ngx-dialog-simulator-prompt',
  templateUrl: 'dialog-simulator-prompt.component.html',
  styleUrls: ['dialog-simulator-prompt.component.scss'],
})
export class DialogSimulatorPromptComponent {

  constructor(protected ref: NbDialogRef<DialogSimulatorPromptComponent>, private router: Router) {}

  cancel() {
    this.ref.close(0);
  }
  

  submit() {
    this.ref.close(1);

  }
}
