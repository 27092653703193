import { NbToastrService } from '@nebular/theme';
import { Component, Input, ElementRef } from '@angular/core';

import * as L from 'leaflet';
import 'style-loader!leaflet/dist/leaflet.css';
import { UnidadeConsumidoraService } from '../../../service/unidade_consumidora.service';
import { Coordenada } from '../../../model/coordenada.model';
import { Router, NavigationExtras } from '@angular/router';
import { UnidadeConsumidora } from '../../../model/unidade_consumidora.model';
import { AlertMsg } from '../../../util/alert/alert.util';

const iconRetinaUrl = 'assets/map/pin.png';
const iconUrl = 'assets/map/pin.png';
const shadowUrl = 'assets/map/marker-shadow.png';

var globalRoute;

@Component({
    selector: 'ngx-leaflet',
    styleUrls: ['./leaflet.component.scss'],
    template: `
    <nb-card>
      <nb-card-body>
          <div leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)" style="height: 300px;"></div>
      </nb-card-body>
    </nb-card>
  `,
})
export class LeafletComponent {
    options = {
        layers: [
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 20, attribution: '...' }),
        ],
        zoom: 5,
        center: L.latLng({ lat: -16.6799, lng: -49.255 }),
        scrollWheelZoom: false
    };

    map;
    coord: Coordenada;
    url: string;
    markers: UnidadeConsumidora[] = []
    icon;
    arrayOfValues;

    constructor(private router: Router, private elementRef: ElementRef, private unidadeConsumidoraService: UnidadeConsumidoraService, private toastrService: NbToastrService) {
        this.url = this.router.url;
        this.setIconMarker()
        globalRoute = this.router;
    }

    setIconMarker() {
        var options = L.Icon.Default.prototype.options;

        options.iconUrl = iconUrl;
        options.shadowUrl = shadowUrl;
        options.iconRetinaUrl = iconRetinaUrl;
        options.iconSize = [31, 41],
        L.Icon.Default.prototype.options = options;
        this.icon = new L.Icon.Default();
    }

    onMapReady(map) {

        // get a local reference to the map as we need it later
        this.map = map;

        this.unidadeConsumidoraService.getTodosDados().subscribe(
            data => {
                this.arrayOfValues = <UnidadeConsumidora[]>data.body;

                this.arrayOfValues.forEach((unidade) => {
                    this.addMarker(unidade)
                })

                this.checkZoomLvl()
            },
            error => {
                AlertMsg.toastResponse(error, this);
            }
        )

        setTimeout(()=>{
            this.map.invalidateSize();
            this.map._resetView(map.getCenter(), map.getZoom(), true);
        },1000);
    }

    addMarker(unidade: UnidadeConsumidora) {
        L.marker(L.latLng({ lat: unidade.lat, lng: unidade.lng }), { icon: this.icon })
            .addTo(this.map)
            .bindPopup(`<a id="a-popup" unidade-id=${unidade.id} equipamento-id=${unidade.equipamentID} style="cursor: pointer;">${unidade.abbreviation}</a>`)
            .on('popupopen', this.redirect);
    }

    checkZoomLvl() {

        if (this.url.includes("site")) {

            var unidade: UnidadeConsumidora = UnidadeConsumidoraService.unidadeSelecionada;
            if (unidade != undefined)
                this.map.setView(L.latLng(unidade.lat, unidade.lng), 6);
        }
        else {
            this.map.setZoom(4)
        }
    }

    redirect() {
        let buttonRedirect = document.querySelector('#a-popup')
        let unidadeId = buttonRedirect.getAttribute('unidade-id');
        let equipamentId = buttonRedirect.getAttribute('equipamento-id');

        buttonRedirect.addEventListener('click', (e) => {
            let navigationExtras: NavigationExtras = {
                queryParams: {
                    unidadeId: unidadeId,
                    equipamentID: equipamentId
                }
            };
            globalRoute.navigate(['/pages/site'], navigationExtras)
        });
    }

    ngOnInit() {

    }

    showToast(status, message) {
        //status: sucess, info, warning, danger
        let position: any = 'top-right';
        this.toastrService.show(
            '',//submensagem
            `${message}`,
            { position, status, destroyByClick: true });
    }

}
