import { Injectable } from '@angular/core';
import { Coordenada } from '../model/coordenada.model';
import { UnidadeConsumidora } from '../model/unidade_consumidora.model';
import { ContratoEnergia } from '../model/contrato_energia.model';
import { ResponsavelSite } from '../model/responsavel_site.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { ServiceMaster } from './service.master';

@Injectable()
export class UnidadeConsumidoraService {

    coord: Coordenada[] = [{ latitude:  -23.610479, longitude: -46.691249}, { latitude: -23.619844, longitude: -46.698918 }]
    static center: Coordenada = {latitude:-16.6799,longitude:-49.255}
    static unidadeSelecionada: UnidadeConsumidora;


    contratoEnergia: ContratoEnergia[] = [{classeTensao: 'A4', tarifa: 'AZUL', demandaContratadaForaPonta: 30, demandaContratadaPonta: 15},
    {classeTensao: 'A4', tarifa: 'AZUL', demandaContratadaForaPonta: 30, demandaContratadaPonta: 15}]
    nome: string;
    email: string;
    telefone: string;
    responsavelSite: ResponsavelSite[] = [{nome: 'Marcela', email:'marcela.lucato@telefonica.com.br', telefone: '(11)9 9999-7777'},{nome: 'Jonas', email: 'jonas@telefonica.com.br', telefone: '(11)9 9915-1511'}]

    constructor(public http: HttpClient){
    }

    getTodosDados()
    {
        return this.http.get(ServiceMaster.baseURL + '/consumerUnit/find/all', { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }

    getById(id)
    {
        return this.http.get(ServiceMaster.baseURL + '/consumerUnit/find/' + id, { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }

    updateConsumerUnit(consumerUnit)
    {
        return this.http.patch(ServiceMaster.baseURL + '/consumerUnit/update/', consumerUnit, { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }

    saveConsumerUnit(consumerUnit)
    {
        return this.http.post(ServiceMaster.baseURL + '/consumerUnit/save/', consumerUnit, { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }

    deleteConsumerUnit(consumerUnitID)
    {
        return this.http.delete(ServiceMaster.baseURL + '/consumerUnit/remove/'+ consumerUnitID, { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }

    getAllEquipaments(id: string) {
        return this.http.get(ServiceMaster.baseURL + '/equipament/find/all/', { headers: ServiceMaster.getHeaders(), observe: 'response' } );
    }

    getAllColdUnits(eqId: string){
        return this.http.get(ServiceMaster.baseURL + '/configIO/findByEq/' + eqId, { headers: ServiceMaster.getHeaders() });
    }

}
