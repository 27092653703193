import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { MESES } from '../../../../../util/legendas_grafico.const';

@Component({
    selector: 'ngx-echarts-bar-report-total-cost',
    template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsBarReportTotalCostComponent implements AfterViewInit, OnChanges {

    @Input() mistoGastosMensais: any[];
    @Input() date: Date

    options: any = {};
    themeSubscription: any;

    dataCenterData = []
    callCenterData = []
    tecnicoData = []
    mistoData = []
    administrativoData = []

    mesesUltimos: string[] = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ', 'JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']
    meses: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
    eixoX: any = ['', '', '', '', '', '', '', '', '', '', '', ''];

    constructor(private theme: NbThemeService) {
    }

    ngOnChanges(changes: import("@angular/core").SimpleChanges): void {

        this.formatXLabel()

        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
            const xAxisData = this.eixoX;
            const dataCenterData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            const callCenterData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            const tecnicoData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            const mistoData = this.mistoGastosMensais;
            const administrativoData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            const colors: any = config.variables;
            const echarts: any = config.variables.echarts;


            var emphasisStyle = {
                itemStyle: {
                    barBorderWidth: 1,
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    shadowColor: 'rgba(0,0,0,0.5)'
                }
            };

            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.primaryLight, colors.infoLight],
                tooltip: {
                    trigger: 'axis',
                    formatter:
                        function (params) {

                            //console.log(params)
                            var texto = '';
                            params.forEach(dados => {

                                texto += dados.seriesName + ':  R$ ' + (dados.data).toLocaleString('pt-br') + '<br>'

                            });

                            // adding X label
                            texto += 'Mês: ' + params[0].axisValueLabel

                            return texto;
                        }
                    , position: function (pos, params, dom, rect, size) {

                        if (size.viewSize[0] < 500) {
                            if (+(pos[0] > size.viewSize[0] * 0.2)) {
                                return [size.viewSize[0] * 0.2, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        } else {
                            if (+(pos[0] > size.viewSize[0] * 0.7)) {
                                return [size.viewSize[0] * 0.7, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        }
                    }

                },
                legend: {
                    data: ['Ponta', 'Fora Ponta'],
                    align: 'left',
                    type: 'scroll',
                    textStyle: {
                        color: echarts.textColor,
                    },

                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        splitLine: { show: false },
                        data: xAxisData,
                        silent: false,
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        name: "[ R$ ]",
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: echarts.splitLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br')
                            }
                        },
                    },
                ],
                series: [
                    {
                        name: 'Data Center',
                        type: 'bar',
                        data: dataCenterData,
                        stack: 'two',
                        emphasis: emphasisStyle,
                        itemStyle: { color: 'rgb(97,152,255)' },
                        animationDelay: idx => idx * 10,
                    },
                    {
                        name: 'Call Center',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: callCenterData,
                        itemStyle: { color: 'rgb(44,230,155)' },
                        animationDelay: idx => idx * 10 + 100,
                    }
                    ,
                    {
                        name: 'Técnico',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: tecnicoData,
                        itemStyle: { color: 'rgb(255,112,141)' },
                        animationDelay: idx => idx * 10 + 100,
                    }
                    ,
                    {
                        name: 'Misto',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: mistoData,
                        itemStyle: { color: 'rgb(66,170,255)' },
                        animationDelay: idx => idx * 10 + 100,
                    },
                    {
                        name: 'Administrativo',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: administrativoData,
                        itemStyle: { color: 'rgb(255,201,77)' },
                        animationDelay: idx => idx * 10 + 100,
                    }

                ],
                animationEasing: 'elasticOut',
                animationDelayUpdate: idx => idx * 5,
            };

        });

    }

    ngAfterViewInit() {
        this.formatXLabel()
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
            const xAxisData = this.eixoX;
            const dataCenterData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            const callCenterData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            const tecnicoData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            const mistoData = this.mistoGastosMensais;
            const administrativoData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            const colors: any = config.variables;
            const echarts: any = config.variables.echarts;

            //console.log('color = ',colors)

            var emphasisStyle = {
                itemStyle: {
                    barBorderWidth: 1,
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    shadowColor: 'rgba(0,0,0,0.5)'
                }
            };

            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.primaryLight, colors.infoLight],
                tooltip: {
                    trigger: 'axis',
                    formatter:
                        function (params) {

                            //console.log(params)
                            var texto = '';
                            params.forEach(dados => {

                                texto += dados.seriesName + ': ' + dados.data + ' R$ ' + '<br>'

                            });

                            // adding X label
                            texto += 'Mês: ' + params[0].axisValueLabel

                            return texto;
                        }

                },
                legend: {
                    data: ['Ponta', 'Fora Ponta'],
                    align: 'left',
                    textStyle: {
                        color: echarts.textColor,
                    },

                },
                grid: {
                    show: false,
                    left: 90,
                    right: 30,
                    bottom: 40,
                    top: 50
                },
                xAxis: [
                    {
                        type: 'category',
                        splitLine: { show: false },
                        data: xAxisData,
                        silent: false,
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            }
                            //formatter: '{value} mês'
                        },
                    },
                ],
                yAxis: [
                    {
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: echarts.splitLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                            formatter: 'R$ {value}'
                        },
                    },
                ],
                series: [
                    {
                        name: 'Data Center',
                        type: 'bar',
                        data: dataCenterData,
                        stack: 'two',
                        emphasis: emphasisStyle,
                        itemStyle: { color: 'rgb(97,152,255)' },
                        animationDelay: idx => idx * 10,
                    },
                    {
                        name: 'Call Center',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: callCenterData,
                        itemStyle: { color: 'rgb(44,230,155)' },
                        animationDelay: idx => idx * 10 + 100,
                    }
                    ,
                    {
                        name: 'Técnico',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: tecnicoData,
                        itemStyle: { color: 'rgb(255,112,141)' },
                        animationDelay: idx => idx * 10 + 100,
                    }
                    ,
                    {
                        name: 'Misto',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: mistoData,
                        itemStyle: { color: 'rgb(66,170,255)' },
                        animationDelay: idx => idx * 10 + 100,
                    },
                    {
                        name: 'Administrativo',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: administrativoData,
                        itemStyle: { color: 'rgb(255,201,77)' },
                        animationDelay: idx => idx * 10 + 100,
                    }

                ],
                animationEasing: 'elasticOut',
                animationDelayUpdate: idx => idx * 5,
            };

        });
    }


    formatXLabel() {
        let data = (this.date) ? this.date : (new Date());

        let mes = data.getMonth();
        for (let i = 11; i >= 0; i--) {
            this.meses[i] = this.mesesUltimos[mes + i + 1];
        }

        this.eixoX = this.meses
    }

}
