import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { NbAuthModule } from '@nebular/auth';
import {
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    NbInputModule,
    NbSpinnerModule
} from '@nebular/theme';
import { ComponentModule } from '../pages/component.module';
import { AuthService } from '../service/auth/auth.service';
import { UserService } from '../service/user.service';
import { NgxAuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';


@NgModule({
    declarations: [LoginComponent],
    imports: [
        NbCardModule,
        NgxAuthRoutingModule,
        CommonModule,
        FormsModule,
        RouterModule,
        NbAlertModule,
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule,
        NgxAuthRoutingModule,
        NbAuthModule,
        NbIconModule,
        NbSpinnerModule,
        ComponentModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    providers: [
        AuthService,
        FormBuilder,
        UserService,
        [{ provide: RECAPTCHA_SETTINGS, useValue: { siteKey: "6LeYRZMqAAAAAPgo80d4SGOvBacYgtZdVhcuVDaU"} as RecaptchaSettings }]
    ]
})
export class NgxAuthModule {
}
