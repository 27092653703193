import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';

@Component({
    selector: 'ngx-echarts-pie-report-co2-type-unit',
    template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsPieReportCo2TypePerUnitComponent implements AfterViewInit, OnChanges {
    @Input() valores: number[]

    options: any = {};
    themeSubscription: any;

    arrayValores = [0, 0]

    constructor(private theme: NbThemeService) {
    }

    ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
        if (this.valores)
            this.arrayValores = this.valores
        else
            this.arrayValores = [0, 0]

        this.carregaGrafico();
    }

    ngAfterViewInit() {
        if (this.valores)
            this.arrayValores = this.valores
        else
            this.arrayValores = [0, 0]

        this.carregaGrafico();
    }

    carregaGrafico() {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

            const colors = config.variables;
            const echarts: any = config.variables.echarts;

            const data1 = ['Cativo', 'Livre']
            const data2 = [
                { value: this.arrayValores[0], name: 'Cativo' },
                { value: this.arrayValores[1], name: 'Livre' }
            ]
            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.warningLight, colors.infoLight, colors.dangerLight, colors.successLight, colors.primaryLight],
                responsive: true,
                tooltip: {
                    trigger: 'item',
                    formatter:
                        function (params) {

                            var value = params.data.value
                            // .toFixed(2).toString();
                            // value = value.replace(/\./g, ",");
                            var msg = params.name + ': <br> ' + (value).toLocaleString('pt-br') + ' Ton CO2 ( ' + (params.percent).toLocaleString('pt-br') + ' %)'

                            return msg;
                        }

                },
                grid:{
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    type: 'scroll',
                    data: data1,
                    textStyle: {
                        color: echarts.textColor,
                    },
                },
                series: [
                    {
                        name: 'Emissão de CO2',
                        type: 'pie',
                        radius: '65%',
                        center: ['50%', '60%'],
                        data: data2,
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: echarts.itemHoverShadowColor,
                            },
                        },
                        label: {
                            normal: {
                                textStyle: {
                                    color: echarts.textColor,
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                lineStyle: {
                                    color: echarts.axisLineColor,
                                },
                            },
                        },
                    },
                ],
            };
        });
    }


}
