import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceMaster } from './service.master';
import { HourlyData } from '../model/hourly_data.model';

@Injectable()
export class HourlyDataService {

    constructor(private http: HttpClient) {
    }

    getHourlyDataTodayByEquipamentId(id: string) {
        return this.http.get(ServiceMaster.baseURL + '/hourly_data/today?equipamentId=' + id, { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }

    getHourlyDataDayByEquipamentId(id: string, day: string) {
        return this.http.get(ServiceMaster.baseURL + '/hourly_data/date?equipamentId=' + id + "&day=" + day, { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }

    getHourlyDataDayByEquipamentIdandDay(id: string, day: Date) {
        return this.http.get(ServiceMaster.baseURL + `/hourly_data/date?equipamentId=${id}&day=${day.toLocaleDateString().split("/").join("-")}`, { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }
}
